<template>
  <div class="permission-setting">
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <v-row class="content" v-else>
      <v-col class="col1" cols="">
        <div class="title color-crm bold-700 font-text lettre-one-capitalize">
          {{ $t('ensemble') }}
        </div>
        <v-divider></v-divider>
        <v-list dense class="list-ensemble">
          <v-list-item-group color="#5C2DD3" class="list-ensemble-item">
            <v-list-item
              button
              v-for="(ensemble, index) in getListePermissionSet"
              :key="ensemble.id"
              @click="handleClickPermissionSet(ensemble)"
              class="custom-list-group custom-list-group-vue"
              :class="{
                'border-top-solid': index == 0
              }"
            >
              <v-list-item-icon
                v-if="
                  selectedPermissionSet &&
                    selectedPermissionSet.id == ensemble.id
                "
              >
                <font-awesome-icon class="color-crm" icon="check" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  ><span class="capitalize">
                    {{ ensemble.name }}</span
                  ></v-list-item-title
                >
              </v-list-item-content>
              <v-menu
                offset-y
                close-on-click
                min-width="197px !important"
                max-width="197px !important"
              >
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    text
                    small
                    v-bind="attrs"
                    v-on="on"
                    fab
                    class=" font-sz-20 mr-2 color-crm"
                  >
                    <font-awesome-icon
                      class="img-h-14 "
                      :class="{
                        'color-crm':
                          selectedPermissionSet &&
                          selectedPermissionSet.id == ensemble.id
                      }"
                      icon="ellipsis-h"
                    />
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item>
                    <v-list-item-title
                      @click.stop="handleUpdateClick(ensemble)"
                    >
                      <v-btn
                        class="icon-action mr-3"
                        small
                        outlined
                        color="#5C2DD3"
                      >
                        <font-awesome-icon class="img-h-14" icon="pencil-alt" />
                      </v-btn>
                      {{ $t('renommer') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click.stop="handleDeleteClick(ensemble)">
                    <v-list-item-title class="text-capitalize">
                      <v-btn
                        class="icon-action mr-3"
                        small
                        outlined
                        color="#5C2DD3"
                      >
                        <font-awesome-icon class="img-h-14" icon="trash-alt" />
                      </v-btn>
                      {{ $t('deleted') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col class="col2" cols="">
        <div class="title block-flex-align-space-between">
          <div class="color-crm bold-700 font-text lettre-one-capitalize">
            {{ $t('utilisateurs') }}
          </div>
          <div>
            <v-progress-circular
              v-if="getUpdateUsersLoading || getPermissionLoading"
              indeterminate
              color="#5C2DD3"
              class="sz-loader-icon"
            ></v-progress-circular>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="bloc-select-multi mt-3" v-if="selectedPermissionSet">
          <v-autocomplete
            :clearable="true"
            color="#5C2DD3"
            multiple
            :placeholder="$t('search')"
            item-color="#5C2DD3"
            v-model="selectedUsers"
            :loading="getUsersLoading"
            :items="listUserComputed || []"
            :persistent-placeholder="true"
            chips
            return-object
            :deletable-chips="true"
            :small-chips="true"
            :label="$t('utilisateurs')"
            class="mb-0"
            item-text="full_name"
            dense
            item-value="id"
            :auto-focus="false"
            :no-data-text="$t('noDataOption')"
            outlined
            :menu-props="{
              bottom: true,
              offsetY: true
            }"
          >
          </v-autocomplete>
          <div class="postion-btn" v-if="selectedUsers && selectedUsers.length">
            <v-btn
              color="#5C2DD3"
              dark
              small
              @click="handleSelectUsers(selectedUsers)"
              >{{ $t('btnEnregistre') }}</v-btn
            >
          </div>
        </div>
        <div class="addListUsers" v-if="selectedPermissionSet">
          <div
            v-if="getUsersLoading || getPermissionLoading"
            class="color-crm font-text font-sz-12 text-center "
          >
            {{ $t('loading') }}
          </div>

          <div
            class="list-users mt-2"
            :class="{ 'refact-height': selectedUsers && selectedUsers.length }"
            v-if="
              selectedPermissionSet.users &&
                selectedPermissionSet.users.length &&
                !getUsersLoading &&
                !getPermissionLoading &&
                !getUpdateUsersLoading
            "
          >
            <v-list class="list-item-user ">
              <v-list-item
                button
                v-for="(item, index) in selectedPermissionSet.users"
                :key="item.id"
                class="custom-list-group custom-list-group-vue"
                :class="{
                  'border-top-solid': index == 0
                }"
              >
                <v-list-item-content>
                  <div class="block-flex-align-space-between">
                    <div>
                      <v-list-item-title
                        ><span class="font-sz-14 font-text bold-600">
                          {{ item.full_name }}</span
                        ></v-list-item-title
                      >
                      <div class="user">
                        <strong class="color-crm font-sz-11 font-text">
                          {{ item.email }}</strong
                        >
                      </div>
                    </div>
                    <v-btn
                      class="color-crm font-sz-14 bold-700 btn-deleted-user"
                      @click.prevent.stop="handleDeleteUserClick(item)"
                      :title="$t('deleted')"
                      fab
                      small
                      icon
                    >
                      X
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div
            class="color-crm font-text font-sz-14 text-center mt-3"
            v-if="
              !getUsersLoading &&
                !getPermissionLoading &&
                !getUpdateUsersLoading &&
                selectedPermissionSet.users &&
                selectedPermissionSet.users.length == 0
            "
          >
            {{ $t('aucun') }} {{ $t('configUser.name') }}
          </div>
        </div>
      </v-col>
      <v-col class="col3" cols="">
        <div class="title block-flex-align-space-between">
          <div class="color-crm bold-700 font-text lettre-one-capitalize">
            {{ $t('autorisations') }}
          </div>
          <div>
            <v-progress-circular
              v-if="getPermissionUpdateLoading || getPermissionLoading"
              indeterminate
              color="#5C2DD3"
              class="sz-loader-icon"
            ></v-progress-circular>
          </div>
        </div>
        <v-divider></v-divider>
        <div v-if="selectedPermissionSet">
          <div
            v-if="getPermissionLoading"
            class="color-crm font-text font-sz-12 mt-2 text-center"
          >
            {{ $t('loading') }}
          </div>
          <div v-else class="mt-2 switch-autorisation">
            <v-switch
              class="input-checkbox ml-2"
              color="#5C2DD3"
              v-for="item in getListePermission"
              :key="item.id"
              v-model="selectedPermission[item.id]"
              :label="item.name"
              @change="UpdateAutorisation($event, item)"
            ></v-switch>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- Delete Permission Modal -->
    <v-dialog v-model="ModalDelete" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? "Supprimer un ensemble d'autorisations"
                : 'Delete set of permissions'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModalDelete')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            {{
              $t('msgdeleted', {
                msg:
                  $i18n.locale === 'fr'
                    ? "l'ensemble d'autorisation"
                    : 'the permission set'
              })
            }}
            <strong>
              {{
                permissionSetToDelete ? permissionSetToDelete.name : ''
              }}</strong
            >
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getPermissionLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getPermissionError" class="error-msg">
              <ul v-if="Array.isArray(getPermissionError)">
                <li v-for="(e, index) in getPermissionError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getPermissionError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="SupprimerPermissionSet"
            :loading="getPermissionLoading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModalDelete')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete utilisteur -->
    <v-dialog v-model="ModalDeleteUsers" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr' ? 'Supprimer un utilisateur' : 'Delete user'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModalDeleteUsers')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? "l'utilisateur" : 'the user'
              })
            }}
            <strong>
              {{
                permissionSetToDeleteUsers
                  ? permissionSetToDeleteUsers.full_name
                  : ''
              }}</strong
            >
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getPermissionLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getPermissionError" class="error-msg">
              <ul v-if="Array.isArray(getPermissionError)">
                <li v-for="(e, index) in getPermissionError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getPermissionError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="supprimerUserPermissionSet"
            :loading="getPermissionLoading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModalDeleteUsers')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- update Permission Modal -->
    <v-dialog v-model="ModalUpdate" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? "Renommer un ensemble d'autorisations"
                : 'Rename a set of permissions'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModalUpdate')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form v-if="permissionSetToUpdate" class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  v-model="nameToUpdate"
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field
              ></v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getPermissionLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getPermissionError" class="error-msg">
              <ul v-if="Array.isArray(getPermissionError)">
                <li v-for="(e, index) in getPermissionError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getPermissionError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="modifierPermissionSet"
            :loading="getPermissionLoading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModalUpdate')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'permission',
  data() {
    return {
      initLoading: true,
      selectedUsers: [],
      selectedPermissionSet: null,
      selectedPermission: [],
      nameToUpdate: null,
      permissionToAdd: {
        name: null,
        permission: null
      },
      permissionSetToUpdate: null,
      permissionSetToDelete: null,
      permissionSetToDeleteUsers: null,
      ModalDelete: false,
      ModalDeleteUsers: false,
      ModalUpdate: false
    }
  },
  methods: {
    ...mapActions([
      'updateUserPermissionSet',
      'deleteUserPermissionSet',
      'deletePermissionSet',
      'updatePermissionPermissionSet',
      'updateNamePermissionSet',
      'fetchListePermission',
      'fetchListePermissionSet',
      'fetchListeUsersPermission',
      'fetchListeUsersAvecPermissionSets',
      'fetchListePermissionSets',
      'resetErrorPermission'
    ]),
    resetModal() {
      this.permissionToAdd = {
        name: null,
        permission: null
      }
      this.selectedPermission = []
      this.selectedPermissionSet = null
      this.permissionSetToUpdate = null
      this.permissionSetToDelete = null
      this.permissionSetToDeleteUsers = null
      this.resetErrorPermission()
    },
    closeDialog(ref) {
      this[ref] = false
    },
    async handleSelectUsers(user) {
      const response = await this.updateUserPermissionSet({
        ensemble: this.selectedPermissionSet,
        usersToAdd: user
      })
      if (response) {
        this.selectedUsers = []
      }
    },
    async SupprimerPermissionSet() {
      await this.deletePermissionSet(this.permissionSetToDelete)
      this.closeDialog('ModalDelete')
      this.resetModal()
    },
    handleUpdateClick(permissionSet) {
      this.permissionSetToUpdate = permissionSet
      this.nameToUpdate = permissionSet.name
      this.ModalUpdate = true
    },
    handleDeleteClick(permissionSet) {
      this.permissionSetToDelete = permissionSet
      this.ModalDelete = true
    },
    handleDeleteUserClick(user) {
      this.permissionSetToDeleteUsers = user
      this.ModalDeleteUsers = true
    },
    async handleClickPermissionSet(permissionSet) {
      this.selectedUsers = []
      this.selectedPermissionSet = permissionSet
      this.fetchListeUsersAvecPermissionSets({ permissionSet })
      await this.fetchListePermissionSets({ permissionSet })
      if (permissionSet) {
        this.selectedPermission = []
        permissionSet.permissions.forEach(p => {
          this.selectedPermission[p.id] = true
        })
      }
    },
    async modifierPermissionSet() {
      if (this.permissionSetToUpdate.name != this.nameToUpdate) {
        const response = await this.updateNamePermissionSet({
          ensemble: this.permissionSetToUpdate,
          data: { name: this.nameToUpdate }
        })
        if (response) {
          this.closeDialog('ModalUpdate')
        }
      }
    },
    async supprimerUserPermissionSet() {
      const response = await this.deleteUserPermissionSet({
        ensemble: this.selectedPermissionSet,
        usersToDelete: this.permissionSetToDeleteUsers
      })
      if (response) {
        this.closeDialog('ModalDeleteUsers')
      }
    },
    async UpdateAutorisation(event, permission) {
      await this.updatePermissionPermissionSet({
        ensemble: this.selectedPermissionSet,
        permissionToAdd: permission,
        add: event
      })
    }
  },
  computed: {
    ...mapGetters([
      'getListePermission',
      'getListePermissionSet',
      'getListeUsers',
      'getPermissionLoading',
      'getPermissionUpdateLoading',
      'getPermissionError',
      'getUsersLoading',
      'getUpdateUsersLoading'
    ]),
    listUserComputed: function() {
      return this.getListeUsers.filter(user => {
        if (this.selectedPermissionSet.users != null) {
          return (
            this.selectedPermissionSet.users.findIndex(
              item => item.id === user.id
            ) < 0
          )
        }
      })
    }
  },
  async mounted() {
    this.initLoading = true
    await this.fetchListePermissionSet()
    this.initLoading = false
    this.fetchListePermission()
    this.fetchListeUsersPermission()
  }
}
</script>
<style lang="scss" scoped>
.permission-setting {
  .content {
    flex-wrap: nowrap;
    .col1 {
      border-right: 2px solid #eeeeee;
      max-width: 100%;
      .margin {
        margin: 12px;
      }
      .title {
        padding: 10px 0px;
      }
      .list-ensemble {
        .list-ensemble-item {
          .custom-list-group {
            border-top: 0px solid #d8d8d8;
            border-bottom: 1px solid #d8d8d8;
            border-left: 1px solid #d8d8d8;
            border-right: 1px solid #d8d8d8;
          }
        }
      }
    }
    .col2 {
      border-right: 2px solid #eeeeee;
      max-width: 100%;
      .title {
        padding: 10px 0px;
      }
      .bloc-select-multi {
        .postion-btn {
          display: flex;
          justify-content: flex-end;
          margin-top: -19px;
          .button-add-users {
            font-size: 11px;
          }
        }
      }
      .addListUsers {
        .chargement {
          text-align: center;
          color: #2dabe2;
        }
        .list-item-user {
          padding: 0px 0 !important;

          .v-list-item__content {
            padding: 10px 0px !important;
          }
          .custom-list-group {
            border-top: 0px solid #d8d8d8;
            border-bottom: 1px solid #d8d8d8;
            border-left: 1px solid #d8d8d8;
            border-right: 1px solid #d8d8d8;
          }
        }
        .list-users {
          max-height: calc(100vh - 383px) !important;
          height: calc(100vh - 383px) !important;
          margin-bottom: 0;
          overflow-y: auto;
          overflow-x: auto;
          &.refact-height {
            max-height: calc(100vh - 440px) !important;
            height: calc(100vh - 440px) !important;
            margin-bottom: 0;
            overflow-y: auto;
            overflow-x: auto;
          }
        }
        .list-users::-webkit-scrollbar {
          width: 7px;
          height: 7px;
        }
        .list-users::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px #f0eef8;
          border-radius: 4px;
          background-color: rgba(238, 238, 238, 1) !important;
        }
        .list-users::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(189, 189, 189, 1);
        }
      }
    }
    .col3 {
      .title {
        padding: 10px 0px;
      }
      .chargement {
        color: #2dabe2;
        margin-bottom: 6px;
      }
      .switch-autorisation {
        max-height: calc(100vh - 305px) !important;
        height: calc(100vh - 305px) !important;
        margin-bottom: 0;
        overflow-y: auto;
        overflow-x: auto;
      }
      .switch-autorisation::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }
      .switch-autorisation::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #f0eef8;
        border-radius: 4px;
        background-color: rgba(238, 238, 238, 1) !important;
      }
      .switch-autorisation::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(189, 189, 189, 1);
      }
    }
  }
}
</style>
<style lang="scss">
.permission-setting {
  .content {
    .col3 {
      .theme--light.v-label {
        font-size: 12px;
      }
    }
  }
}
</style>
